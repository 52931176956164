import React from 'react';

const Logo = () => {
    return (
        <div>
            <div className="p-20">
                <a href={process.env.REACT_APP_SITE_URL} className="b-brand">
                    <img style={{width: '100px'}} src='/img/logo.svg' alt='BUDZ token logo'/>
                </a>
            </div>
        </div>
    );
};

export default Logo;